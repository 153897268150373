<template>
  <div class="dialog__wrapper">
    <Dialog :name="name" width="728px" :class="mobileView">
      <template #title>
        <div>Подтверждение операции</div>
      </template>

      <div>
        <div v-if="isMultiple" class="dialog__content">
          Вы действительно хотите удалить выделенные авто из списка транспорта
          терминала?
          <ol>
            <li v-for="item in getTableRowParams.namesList" :key="item">
              <b>{{ item }}</b>
            </li>
          </ol>
        </div>

        <div v-else class="dialog__content">
          Вы действительно хотите удалить авто с номером
          <b>{{ getTableRowParams.plate_truck }}</b>
          из списка транспорта терминала?
        </div>

        <div class="df jc-sb">
          <iq-mobile-button
            button-style="padding: 12px; width: 100%; margin-right: 4px"
            text-style="color: #000000; font-weight: 500"
            title="Нет"
            :disabled="getIsLoading"
            @onClick="handleRemoveSuppliers(false)"
          />
          <iq-mobile-button
            button-style="padding: 12px; width: 100%; margin-left: 4px; background: #F56C6C; border-color: #F56C6C; color: white"
            text-style="color: white; font-weight: 500"
            title="Да"
            :loading="getIsLoading"
            @onClick="handleRemoveSuppliers(true)"
          />
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { CONTROL_TABLE_WHITELIST_REMOVE_ROW } from '@/constants/dialogs'
import {
  DELETE_WHITELIST,
  GET_IS_LOADING_WHITELIST,
} from '@/views/control/store/actions'
import { GET_IS_MOBILE } from '@/store/actions'
import { mapActions, mapGetters } from 'vuex'
import Dialog from '@/UI/dialog/Dialog'
import IqMobileButton from '@/UI/form/mobileButton/IqMobileButton'

export default {
  name: 'DialogRemoveTableRow',
  components: { Dialog, IqMobileButton },
  props: { params: { type: Object, default: () => ({}) } },
  data() {
    return { name: CONTROL_TABLE_WHITELIST_REMOVE_ROW }
  },
  computed: {
    ...mapGetters({
      getIsLoading: GET_IS_LOADING_WHITELIST,
      isMobile: GET_IS_MOBILE,
    }),
    getTableRowParams() {
      return this.getDialog(this.name).data || {}
    },
    isMultiple() {
      return this.getTableRowParams.isMultipleDelete
    },
    mobileView() {
      return this.isMobile ? 'alert-dialog' : 'remove-table-row'
    },
  },
  methods: {
    ...mapActions({ deleteWhitelist: DELETE_WHITELIST }),
    handleRemoveSuppliers(isRemove = false) {
      if (isRemove) {
        const callback = this.closeDialog
        const id = this.isMultiple
          ? this.getTableRowParams.idsList
          : [this.getTableRowParams.id]

        this.deleteWhitelist({ id, callback, params: this.params })
      } else {
        this.closeDialog()
      }
    },
    closeDialog() {
      this.setDialog({ name: this.name })
    },
  },
}
</script>

<style lang="sass">
.el-button + .el-button
  margin-left: 35px

.dialog__wrapper
  .remove-table-row
    .el-dialog__body
      .dialog__content
        margin-bottom: 120px
      .alert__buttons
        display: flex
        justify-content: flex-end
        &-button
          width: 60px
.dialog__wrapper
  .alert-dialog
    .el-dialog__header
      background-color: $color-red !important
    .el-dialog__body
      .dialog__content
        margin-bottom: 24px
        font-weight: 500
        font-size: 16px
        ol
          padding-left: 6px
        & li::before
          color: #303133 !important
          font-weight: 400 !important
          font-size: 14px
      .alert__buttons
        display: flex
        justify-content: space-between
        &-button
          width: 48%
          height: 32px
          padding: 0 20px
          font-weight: 500 !important
        .leftButtonMobile
          border: $border-grey !important
          background-color: $backgroundGrayTwo !important
          color: $color-black
        .rightButtonMobile
          background-color: $color-red !important
</style>
